import { useEffect } from "react";
import { useMoralis } from "react-moralis";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import Account from "components/Account/Account";
import TokenPrice from "components/TokenPrice";
// import ERC20Balance from "components/ERC20Balance";
// import ERC20Transfers from "components/ERC20Transfers";
// import DEX from "components/DEX";
// import NFTBalance from "components/NFTBalance";
// import Wallet from "components/Wallet";
import { Layout, Tabs } from "antd";
import "antd/dist/antd.less";
import "./style.less";
import Gamify from "components/Gamify";
// import Contract from "components/Contract/Contract";
// import Ramper from "components/Ramper";
import MenuItems from "./components/MenuItems";
import logo from "Logo.png";
import { TrophyFilled, GiftFilled, SketchOutlined } from "@ant-design/icons";
const { Header } = Layout;

const styles = {
  content: {
    display: "flex",
    justifyContent: "center",
    fontFamily: "Roboto, sans-serif",
    color: "#041836",
    marginTop: "130px",
    padding: "10px",
  },
  header: {
    position: "fixed",
    zIndex: 1,
    width: "100%",
    background: "linear-gradient(#2E2E2E, #1E1E1E)",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    fontFamily: "Roboto, sans-serif",
    borderBottom: "2px solid rgba(0, 0, 0, 0.06)",
    padding: "10px 10px",
    boxShadow: "0 1px 10px rgb(151 164 175 / 10%)",
  },
  headerRight: {
    display: "flex",
    gap: "20px",
    alignItems: "center",
    fontSize: "16px",
    fontWeight: "600",
  },
};
const App = ({ isServerInfo }) => {
  const { isWeb3Enabled, enableWeb3, isAuthenticated, isWeb3EnableLoading } =
    useMoralis();

  useEffect(() => {
    const connectorId = window.localStorage.getItem("connectorId");
    if (isAuthenticated && !isWeb3Enabled && !isWeb3EnableLoading)
      enableWeb3({ provider: connectorId });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, isWeb3Enabled]);

  return (
    <Layout
      style={{
        height: "100vh",
        overflow: "auto",
        background: "linear-gradient(#2E2E2E, #1E1E1E)",
      }}
    >
      <Router>
        <Header style={styles.header}>
          <a href="/" style={{ width: "48px" }}><img src={logo} /></a>
          <MenuItems />
          <div style={styles.headerRight}>
            <TokenPrice
              address="0x1f9840a85d5af5bf1d1762f925bdaddc4201f984"
              chain="eth"
              image="https://cloudflare-ipfs.com/ipfs/QmXttGpZrECX5qCyXbBQiqgQNytVGeZW5Anewvh2jc4psg/"
              size="40px"
            />
            <Account />
          </div>
        </Header>

        <div style={styles.content}>
          <Switch>
            <Route exact path="/">
              <Tabs
                defaultActiveKey="1"
                tabPosition="left"
              >
                <Tabs.TabPane tab={<span><SketchOutlined /> Kudasai  </span>} key="1">
                  <Gamify tab="kudasai" />
                </Tabs.TabPane>
                <Tabs.TabPane tab={<span><GiftFilled /> Agemasu  </span>} key="3">
                  <Gamify tab="agemasu" />
                </Tabs.TabPane>
                {/* <Tabs.TabPane tab={<span><TrophyFilled /> Ranking  </span>} key="2">
                  <Gamify tab="ranking" />
                </Tabs.TabPane> */}
              </Tabs>
            </Route>
            {/* <Route path="/wallet">
              <Wallet />
            </Route>
            <Route path="/1inch">
              <Tabs defaultActiveKey="1" style={{ alignItems: "center" }}>
                <Tabs.TabPane tab={<span>Ethereum</span>} key="1">
                  <DEX chain="eth" />
                </Tabs.TabPane>
                <Tabs.TabPane tab={<span>Binance Smart Chain</span>} key="2">
                  <DEX chain="bsc" />
                </Tabs.TabPane>
                <Tabs.TabPane tab={<span>Polygon</span>} key="3">
                  <DEX chain="polygon" />
                </Tabs.TabPane>
              </Tabs>
            </Route>
            <Route path="/erc20balance">
              <ERC20Balance />
            </Route>
            <Route path="/onramp">
              <Ramper />
            </Route>
            <Route path="/erc20transfers">
              <ERC20Transfers />
            </Route>
            <Route path="/nftBalance">
              <NFTBalance />
            </Route>
            <Route path="/contract">
              <Contract />
            </Route>*/}
            {/* <Route path="/">
              <Redirect to="/gamify" />
            </Route> */}
            {/* <Route path="/ethereum-boilerplate">
              <Redirect to="/gamify" />
            </Route>
            <Route path="/nonauthenticated">
              <>Please login using the "Authenticate" button</>
            </Route> */}
          </Switch>
        </div>
      </Router>
    </Layout>
  );
};

export default App;
