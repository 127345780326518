import { Typography, Tag, Button, Table, Space, Card, Modal } from "antd";
import { SketchOutlined } from "@ant-design/icons";
import Runes from "../Runes.png";
import RunesCollected from "../RunesCollected.png";
import Mages from "../Mages.png";
import Hoodie from "../Hoodie.png";
import Blockie from "./Blockie";
import { getEllipsisTxt } from "helpers/formatters";
import { useMoralis, useWeb3ExecuteFunction } from "react-moralis";
import moment from "moment";
import useCollectors from "hooks/useCollectors";
import { useState, useEffect } from "react";

const { Title } = Typography;

const styles = {
  collected: {
    marginTop: "20px",
    marginBottom: "40px",
    width: "350px",
    height: "190px",
    background: "#D76425",
    borderRadius: "20px",
    display: "flex",
    overflow: "hidden",
  },
  colHeading: {
    padding: "27px",
    fontSize: "14px",
    width: "280px",
  },
  count: {
    fontSize: "30px",
    fontWeight: "600",
    marginTop: "5px",
  },
  daily: {
    marginTop: "20px",
    marginBottom: "35px",
    display: "flex",
    justifyContent: "space-between",
  },
  rew: {
    marginTop: "20px",
    marginBottom: "35px",
    display: "flex",
    gap: "35px",
    flexWrap: "wrap",
  },
  collect: {
    background: "#40E0D0",
    borderColor: "#40E0D0",
    width: "150px",
  },
  cantCollect: {
    background: "#c5c9c7",
    borderColor: "#c5c9c7",
    width: "150px",
  },
  claimrow: {
    display: "flex",
    gap: "25px",
    marginBottom: "35px",
    flexWrap: "wrap",
  },
  rewardCard: {
    width: "310px",
    height: "400px",
    borderRadius: "15px",
  },
  rewardImg: {
    height: "200px",
    overflow: "hidden",
    borderRadius: "15px 15px 0 0",
  },
  bottom: {
    position: "absolute",
    bottom: "24px",
    left: "24px",
    width: "262px",
    display: "flex",
    justifyContent: "space-between",
  },
};

export default function Gamify({ tab }) {
  const { Moralis, account, isInitialized, isAuthenticated } = useMoralis();
  const contractProcessor = useWeb3ExecuteFunction();
  const days = [10, 20, 30, 40, 50, 80, 100];
  const { getDays, getLeaderBoard } = useCollectors();

  const [daysStreak, setDaysStreak] = useState(-1);
  const [collected, setCollected] = useState(true);
  const [userRunes, setUserRunes] = useState(0);
  const [dataSource, setDataSource] = useState();

  function getUsers(i) {
    if (i === daysStreak && !collected) {
      return "runeBtn2";
    } else {
      return "runeBtn";
    }
  }

  async function mintNFT() {
    if (userRunes < 2000) {
      let secondsToGo = 5;
      const modal = Modal.error({
        title: "Agemasen!",
        content: `Kudasai Gemの数を必要な量まで貯めましょう！毎日Kudasaiしてね！`,
      });
      setTimeout(() => {
        modal.destroy();
      }, secondsToGo * 1000);
      return;
    }

    let options = {
      contractAddress: "0x8A296092241568F038f8bCC211466ed015453352",
      functionName: "createToken",
      abi: [
        {
          inputs: [],
          name: "createToken",
          outputs: [
            {
              internalType: "uint256",
              name: "",
              type: "uint256",
            },
          ],
          stateMutability: "nonpayable",
          type: "function",
        },
      ],
    };

    await contractProcessor.fetch({
      params: options,
      onSuccess: () => {
        let secondsToGo = 10;
        const modal = Modal.success({
          title: "Agemasu!",
          content: `特別なNFTがmintされました！確認してね！`,
        });
        setTimeout(() => {
          modal.destroy();
        }, secondsToGo * 1000);
      },
    });
  }

  useEffect(() => {
    if (isInitialized && isAuthenticated) {
      const fetchStreak = async () => {
        const data = await getDays();
        const { daysInARow, lastCollected, runes } = data.attributes;
        setDaysStreak(daysInARow);
        setCollected(moment(lastCollected).isSame(moment.utc(), "day"));
        setUserRunes(runes);
        const dataLeader = await getLeaderBoard();
        setDataSource(dataLeader);
      };
      fetchStreak();
    } else {
      setDaysStreak(-1);
      setCollected(true);
      setUserRunes(0);
    }
  }, [isInitialized, isAuthenticated]);

  async function addRunes() {
    const users = Moralis.Object.extend("RuneCollectors");
    const query = new Moralis.Query(users);
    query.equalTo("ethAddress", account);
    const data = await query.first();
    const { lastCollected, daysInARow, runes } = data.attributes;

    if (!lastCollected || !moment(lastCollected).isSame(moment.utc(), "day")) {
      data.increment("runes", days[daysInARow]);
      data.set("lastCollected", moment.utc().format());
      setCollected(true);
      setUserRunes(runes + days[daysInARow]);
      if (daysInARow === 6) {
        data.set("daysInARow", 0);
        setDaysStreak(0);
      } else {
        setDaysStreak(daysInARow + 1);
        data.increment("daysInARow");
      }
      data.save();
      succCollect(days[daysInARow]);
    } else {
      failCollect();
    }
  }

  function succCollect() {
    let secondsToGo = 5;
    const modal = Modal.success({
      title: "Agemasu!",
      content: (
        <>
          <p>Kudasaiが成功しました</p>
          <img src={Runes} alt="" style={{ width: "280px" }} />
        </>
      ),
    });
    setTimeout(() => {
      modal.destroy();
    }, secondsToGo * 1000);
  }

  function failCollect() {
    let secondsToGo = 5;
    const modal = Modal.error({
      title: "Agemasen!",
      content: `Kudasaiは１日１回まで`,
    });
    setTimeout(() => {
      modal.destroy();
    }, secondsToGo * 1000);
  }

  const columns = [
    {
      title: "ランク",
      dataIndex: "rank",
      key: "rank",
    },
    {
      title: "アカウント",
      key: "ethAddress",
      render: (text, record) => (
        <Space size="middle">
          <Blockie
            style={{ border: "solid 2px white" }}
            address={record.ethAddress}
            scale={3}
          />
          <span>{getEllipsisTxt(record.ethAddress, 6)}</span>
        </Space>
      ),
    },
    {
      title: "累計",
      dataIndex: "runes",
      key: "runes",
      align: "right",
    },
  ];

  if (tab === "kudasai") {
    return (
      <div style={{ paddingLeft: "5vw", width: "70vw" }}>
        <Title level={2} style={{ color: "white" }}>
          Mainichi Kudasai
        </Title>
        <p style={{ color: "gray" }}>
        Hi, nice project! 毎日Kudasaiしてポイントを貯めよう!<br />
        貯まったポイントに応じて<s>ryutaroから</s>ギバウェイがあるかも👀
        </p>

        <div style={styles.collected}>
          <div style={styles.colHeading}>
            <span>Kudasai累計pt</span>
            <p style={styles.count}>{userRunes}</p>
          </div>
          <div>
            <img src={Runes} alt="" />
          </div>
        </div>

        {/* <Tag color="rgba(47,79,79, 0.2)" style={{ color: "#D76425" }}>
          Kudasaiする
        </Tag> */}

        <div style={styles.daily}>
          <div>
            <Title level={3} style={{ color: "white" }}>
              Let's Kudasai !
            </Title>
            <p style={{ color: "gray" }}>
              毎日1回、Kudasaiができます。一日も休まずKudasaiすれば貯まるポイントもアップします🚀<br />
              貫通はしないでkudasai🥺
            </p>
          </div>
          <Button
            style={collected ? styles.cantCollect : styles.collect}
            onClick={() => addRunes()}
          >
            Kudasai !!
          </Button>
        </div>
        <div style={styles.claimrow}>
          {days.map((e, i) => (
            <>
              <div className={getUsers(i)} style={{ background: "#D76425" }}>
                <p style={{ fontSize: "13px", color:"white"}}>{`Day ${i + 1}`}</p>
                {i > daysStreak - 1 ? (
                  <img
                    src={Runes}
                    alt=""
                    style={{ width: "40%", margin: "6px auto" }}
                  />
                ) : (
                  <img
                    src={RunesCollected}
                    alt=""
                    style={{ width: "60%", margin: " auto" }}
                  />
                )}
                <p style={{ color: "white", fontSize: "18px" }}>{`+${e}`}</p>
              </div>
            </>
          ))}
        </div>
        <span style={{ color: "gray" }}>
          Looking for nice projects?{" "}
          <a href="https://t.me/KudasaiJP" target="_blank" style={{ color: "#D76425" }}>Join telegram!</a>
        </span>
      </div>
    );
  }

  if (tab === "ranking") {
    return (
      <div style={{ paddingLeft: "5vw", width: "70vw" }}>
        <Title level={2} style={{ color: "white" }}>
          Kudasai Ranking
        </Title>
        <p style={{ color: "gray" }}>
          最もKudasaiをしている猛者達
        </p>
        {dataSource && (
          <Table
            style={{ marginTop: "35px" }}
            dataSource={dataSource}
            columns={columns}
          />
        )}
      </div>
    );
  }

  if (tab === "agemasu") {
    return (
      <div style={{ paddingLeft: "5vw", width: "70vw" }}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Title level={2} style={{ color: "white" }}>
            Agemasu
          </Title>
          <Space size={"small"}>
            <span style={{ color: "gray" }}> My Kudasai pt:</span>
            <Tag color={"#324252"} style={{ height: "22px" }}>
              <SketchOutlined /> {userRunes}
            </Tag>
          </Space>
        </div>

        <p style={{ color: "gray", marginBottom: "35px" }}>
          Kudasai Pointを貯めるとなんかいろいろ交換できるかも。
        </p>

        <Tag color="rgba(47,79,79, 0.2)" style={{ color: "#D76425" }}>
          Reward一覧
        </Tag>
        <div style={styles.rew}>
          <Card
            // onClick={() => mintNFT()}
            hoverable
            style={styles.rewardCard}
            cover={
              <div style={styles.rewardImg}>
                <img src={Mages} alt=""></img>
              </div>
            }
          >
            <Title level={5} style={{ color: "white" }}>
              Coming soon...?
            </Title>
            <p style={{ color: "gray" }}>
              {/* Collect enough runes to earn the title of Rune Collector and join
              a community of Mage NFT holders. */}
            </p>
            <div style={styles.bottom}>
              <Space size={"small"}>
                <span style={{ color: "gray", fontSize:"0.8em" }}> 必要pt:</span>
                <Tag color={"#324252"} style={{ height: "22px" }}>
                  <SketchOutlined /> ?????
                </Tag>
              </Space>
              {/* <span style={{ color: "gray" }}> Supply: ???/???</span> */}
            </div>
          </Card>
          {/* <Card
            hoverable
            style={styles.rewardCard}
            cover={
              <div style={styles.rewardImg}>
                <img src={Hoodie} alt=""></img>
              </div>
            }
          >
            <Title level={5} style={{ color: "white" }}>
              Moralis Merch - Hoodie
            </Title>
            <p style={{ color: "gray" }}>
              Upgrade your wardrobe, by coverting your runes into some fresh
              Moralis swag!
            </p>
            <div style={styles.bottom}>
              <Space size={"small"}>
                <span style={{ color: "gray" }}> Price:</span>
                <Tag color={"#324252"} style={{ height: "22px" }}>
                  <SketchOutlined /> 10000
                </Tag>
              </Space>
              <span style={{ color: "gray" }}> Supply: Infinite</span>
            </div>
          </Card> */}
        </div>
      </div>
    );
  }
}
