import { SketchOutlined, TwitterOutlined } from "@ant-design/icons";
import telegram from "telegram.png";
import discord from "discord.png";

const styles = {
  token: {
    padding: "0 7px",
    height: "36px",
    gap: "20px",
    width: "fit-content",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    whiteSpace: "nowrap",
  },
};
function TokenPrice() {


  return (
    <div style={styles.token}>
      <a href="https://twitter.com/kudasai_japan" target="_blank" style={{ fontSize: "24px" }}><TwitterOutlined /></a>
      <a href="https://t.me/KudasaiJP" target="_blank" style={{ width: "24px" }}><img src={telegram} /></a>
      <a href="https://discord.com/invite/kudasaijp" target="_blank" style={{ width: "24px" }}><img src={discord} /></a>
      {/* <SketchOutlined />
      <span >
        Hi, nice project!
      </span> */}
    </div>
  );
}
export default TokenPrice;
